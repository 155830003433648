import FeatureGates from '@atlaskit/feature-gate-js-client';

export enum CreateFromContentTreeCohorts {
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	TEST = 'test',
}

export const CREATE_FROM_CONTENT_TREE_EXPERIMENT = 'confluence_create_from_content_tree_experiment';

export const useCreateFromContentTreeEligible = () => {
	const cohort = FeatureGates.getExperimentValue<CreateFromContentTreeCohorts>(
		CREATE_FROM_CONTENT_TREE_EXPERIMENT,
		'cohort',
		CreateFromContentTreeCohorts.NOT_ENROLLED,
		{
			fireExperimentExposure: false,
		},
	);

	const isCreateFromContentTreeEnrolled = cohort !== CreateFromContentTreeCohorts.NOT_ENROLLED;

	const isCreateFromContentTreeEligible = cohort === CreateFromContentTreeCohorts.TEST;

	return {
		cohort,
		isCreateFromContentTreeEnrolled,
		isCreateFromContentTreeEligible,
	};
};
